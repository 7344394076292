.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
}

.play-button {
  padding: 20px 40px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background-color: #ff5722;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.play-button:hover {
  background-color: #e64a19;
}

.video {
  display: none;
  width: 100%;
  height: 100%;
}

video:fullscreen {
  display: block;
}

.download-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color: #4caf50;
  border-radius: 5px;
}

.download-button:hover {
  background-color: #388e3c;
}
